import React from "react"
import Typography from "@mui/material/Typography"

const PrivacyPolicy = () => {
  return (
    <div>
      <Typography variant="h1">COOKIE POLICY</Typography>
      <Typography variant="h2">INFORMATION ABOUT OUR USE OF COOKIES</Typography>
      <Typography variant="body1">
        Our website uses cookies to distinguish you from other users of our
        website. This helps us to provide you with a good experience when you
        browse our website and also allows us to improve our site. A cookie is a
        small file of letters and numbers that we store on your browser or the
        hard drive of your computer if you agree. Cookies contain information
        that is transferred to your computer's hard drive.
      </Typography>
      <Typography variant="body1">We use the following cookies:</Typography>
      <ul>
        <li>
          <Typography variant="body1">Strictly necessary cookies</Typography>
          <Typography variant="body1">
            These are cookies that are required for the operation of our
            website. They include, for example, cookies that enable you to log
            into secure areas of our website, use a shopping cart or make use of
            e-billing services.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Analytical/performance cookies.
          </Typography>
          <Typography variant="body1">
            They allow us to recognise and count the number of visitors and to
            see how visitors move around our website when they are using it.
            This helps us to improve the way our website works, for example, by
            ensuring that users are finding what they are looking for easily.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">Functionality cookies.</Typography>
          <Typography variant="body1">
            These are used to recognise you when you return to our website. This
            enables us to personalise our content for you, greet you by name and
            remember your preferences (for example, your choice of language or
            region).
          </Typography>
        </li>
        <li>
          <Typography variant="body1">Targeting cookies.</Typography>
          <Typography variant="body1">
            These cookies record your visit to our website, the pages you have
            visited and the links you have followed. We will use this
            information to make our website and the advertising displayed on it
            more relevant to your interests.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">Social Media cookies</Typography>
          <Typography variant="body1">
            These cookies exist in any social media share or follow links you
            may wish to click, providing the third party website with
            information that you came from this website.
          </Typography>
        </li>
      </ul>
    </div>
  )
}

export default PrivacyPolicy
